/*
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2021-12-30 17:20:46
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-04-01 13:01:16
 * @FilePath: \ra-web-admin\src\api\jt808\deviceEmployee.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const baseURL = process.env.VUE_APP_JT_API

// 查询设备-雇员绑定列表
export function listDeviceEmployee(query) {
  return request({
    url: '/deviceEmployee/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询设备-雇员绑定详细
export function getDeviceEmployee(id) {
  return request({
    url: '/deviceEmployee/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增设备-雇员绑定
export function addDeviceEmployee(data) {
  return request({
    url: '/deviceEmployee',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改设备-雇员绑定
export function updateDeviceEmployee(data) {
  return request({
    url: '/deviceEmployee',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除设备-雇员绑定
export function delDeviceEmployee(id) {
  return request({
    url: '/deviceEmployee/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出设备-雇员绑定
export function exportDeviceEmployee(query) {
  return request({
    url: '/deviceEmployee/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 新增设备-绑定
export function bindDeviceEmployee(data) {
  return request({
    url: '/deviceEmployee/bind',
    baseURL,
    method: 'post',
    data: data
  })
}

// 解除绑定
export function unbindDeviceEmployee(data) {
  return request({
    url: '/deviceEmployee/unbind',
    baseURL,
    method: 'post',
    data: data
  })
}

export function checkUserEmployee(query) {
  return request({
    url: '/jt808/device/checkUser',
    method: 'get',
    params: query
  })
}
